import React from 'react'
import { Link } from 'gatsby'

export default function Navbar() {
    return (
        <div className="bg-ash-light">
            <div className="flex flex-row p-4 max-w-7xl mx-auto filter drop-shadow-lg">
                <div className="flex flex-1 items-center">
                    <Link className="flex items-center" to="/">
                        <img src="/logo.svg" className="w-8 filter drop-shadow-lg" alt="Logo" />
                        <div className="text-ash-bright font-roboto font-bold text-xl ml-4 filter drop-shadow-lg">
                            Balisong Mastery
                        </div>
                    </Link>
                </div>
                {/* <div className="flex flex-col text-ash-bright font-roboto font-medium text-lg">
                    <div className="flex flex-1 items-center filter drop-shadow-lg">
                        Home
                    </div>
                </div> */}
            </div>
        </div>
    )
}
