import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
    return (
        <div className="flex flex-col p-10 mt-20 max-w-7xl mx-auto filter">
            <div className="flex font-open-sans font-regular text-xs text-center text-ash-dim justify-around">
                <Link className="underline" to="/legal/privacy-policy">
                    Privacy Policy
                </Link>
                <Link className="underline" to="/legal/terms-and-conditions">
                    Terms and Conditions
                </Link>
            </div>
            {/* <div className="font-open-sans font-regular text-xs text-center text-ash-dim mt-2">
                Balisong Mastery and the Balisong Mastery logo<br />
                are trademarks of The Everything Project LLC.
            </div> */}
            <div className="font-open-sans font-regular text-xs text-center text-ash-dim mt-2">
                Balisong Mastery © The Everything Project, LLC.
            </div>
        </div>
    )
}
