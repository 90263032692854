import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

export default function Layout({ children }) {
    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-ash-light to-ash-dark bg-gradient-to-br">
            <Navbar />
            { children }
            <Footer />
        </div>
    )
}
